<template>
  <form @submit.prevent="login" autocomplete="off">
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="login-wrap">
        <div class="login-html">
          <div class="logo">
            <img :src="'/media/logo-horz-' + mode + '.png'" alt="" />
          </div>
          <div class="login-form">
            <TextTemplate
              label="Kullanıcı Adı"
              :value="formData.email"
              keyName="formData.email"
              type="text"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <TextTemplate
              label="Şifre"
              :value="formData.password"
              keyName="formData.password"
              type="password"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.password.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <a
              :href="$env.stage.API_URL + '/user/password_reset/'"
              class="btn btn-empty-primary w-auto ml-auto p-0 minw-auto"
              >Şifremi Unuttum</a
            >
            <div class="group">
              <button type="submit" class="btn btn-filled-primary w-100 mt-50">
                Giriş Yap
              </button>
            </div>
          </div>
        </div>
        <span class="no-user" :class="getThrowError ? 'error-show' : ''">
          <img src="/media/error-face.png" alt="" />
          {{ getThrowError }}
        </span>
      </div>
    </div>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      formData: {
        email: "",
        password: "",
      },
      mode: "light",
    };
  },
  validations() {
    return {
      formData: {
        email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        password: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      getThrowError: "getThrowError",
    }),
  },
  methods: {
    ...mapActions("auth", {
      loginReq: "loginReq",
    }),
    async login() {
      this.v$.$touch();
      if (!this.v$.$error) {
        await this.loginReq(this.formData);
      }
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.value; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {};
          }
          target = target[keynames[i]]; // Yolu takip et
        }
      }
    },
  },
  created() {
    this.mode = this.getCookie("theme-mode") || "light";
  },
};
</script>